import mixpanel from 'mixpanel-browser';
import { REACT_APP_MIXPANEL_CONFIG_ID } from '../utils/envVar';

const analyticConfig: any = {
  id: REACT_APP_MIXPANEL_CONFIG_ID,
};

mixpanel.init(analyticConfig.id, {
  debug: true,
});

export default mixpanel;
