import {
  ADD_USER_SAVE_BEGIN,
  ADD_USER_SAVE_FAILURE,
  ADD_USER_SAVE_SUCCESS,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_ROLE_BEGIN,
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_SUCCESS,
  SET_VIEW_PREFERENCE,
} from '../constant';

const initialState = {
  getUserDetails: {
    loading: false,
    data: [],
    error: null,
  },
  getUserRole: {
    loading: false,
    data: [],
    error: null,
  },
  addUserSave: {
    loading: false,
    data: [],
    error: null,
  },
  viewPreference: 'card',
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_DETAILS_BEGIN:
      return {
        ...state,
        getUserDetails: {
          ...state.getUserDetails,
          loading: true,
        },
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getUserDetails: {
          ...state.getUserDetails,
          loading: false,
          data: action.payload,
        },
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getUserDetails: {
          ...state.getUserDetails,
          loading: false,
          error: action.payload,
        },
      };
    case GET_USER_ROLE_BEGIN:
      return {
        ...state,
        getUserRole: {
          ...state.getUserRole,
          loading: true,
        },
      };
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        getUserRole: {
          ...state.getUserRole,
          loading: false,
          data: action.payload,
        },
      };
    case GET_USER_ROLE_FAILURE:
      return {
        ...state,
        getUserRole: {
          ...state.getUserRole,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_USER_SAVE_BEGIN:
      return {
        ...state,
        addUserSave: {
          ...state.addUserSave,
          loading: true,
        },
      };
    case ADD_USER_SAVE_SUCCESS:
      return {
        ...state,
        addUserSave: {
          ...state.addUserSave,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_USER_SAVE_FAILURE:
      return {
        ...state,
        addUserSave: {
          ...state.addUserSave,
          loading: false,
          error: action.payload,
        },
      };
    case SET_VIEW_PREFERENCE:
      return {
        ...state,
        viewPreference: action.payload,
      };
    default:
      return state;
  }
};
