import {
  changeStatusLabelType,
  CreateThreatActionType,
} from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getThreatApi = async (payload: any) => {
  let url = `v2/assets/threats?from=${payload.from}&until=${payload.until}&threat_name=${payload.threat_name}&sort=${payload.sort}&order=${payload.order}&page=${payload.page}&size=${payload.size}`;

  if (payload.company_ids && payload.company_ids.length > 0) {
    const companyIdsQuery = payload.company_ids
      .map((id: string) => `company_ids=${id}`)
      .join('&');
    url += `&${companyIdsQuery}`;
  }

  if (payload.status && payload.status.length > 0) {
    const statusLabelsQuery = payload.status
      .map((label: string) => `status=${label}`)
      .join('&');
    url += `&${statusLabelsQuery}`;
  }

  if (payload.service && payload.service.length > 0) {
    const serviceLabelsQuery = payload.service
      .map((label: string) => `service=${label}`)
      .join('&');
    url += `&${serviceLabelsQuery}`;
  }

  if (payload.sources && payload.sources.length > 0) {
    const sourcesLabelsQuery = payload.sources
      .map((label: string) => `sources=${label}`)
      .join('&');
    url += `&${sourcesLabelsQuery}`;
  }

  if (payload.excludeCompanysId && payload.excludeCompanysId.length > 0) {
    const excludeLabelsQuery = payload.excludeCompanysId
      .map((label: string) => `exclude_company_ids=${label}`)
      .join('&');
    url += `&${excludeLabelsQuery}`;
  }
  const config = {
    method: 'GET',
    url: url,
    payload,
  };
  return await apiClient(config);
};

export const getThreatDetailApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${payload.protected_id}/threats/${payload.threat_id}`,
  };
  return await apiClient(config);
};

export const getThreatScreenshot = async (ThreatName: string | undefined) => {
  const config = {
    method: 'GET',
    url: `domain/screenshot?d=${ThreatName}&j=y`,
  };
  return await apiClient(config);
};

export const changeStatusLabelApi = async (data: changeStatusLabelType) => {
  const config = {
    method: 'POST',
    url: `v2/assets/${data.protected_asset_id}/threats`,
    data,
  };
  return await apiClient(config);
};

export const changeBulkStatusApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `v2/assets/threats`,
    data,
  };
  return await apiClient(config);
};

export const createThreatApi = async (data: CreateThreatActionType) => {
  const config = {
    method: 'POST',
    url: `v2/assets/threats`,
    data: {
      items: data.items,
    },
  };
  return await apiClient(config);
};

export const getDomainsListApi = async (payload: string) => {
  const config = {
    method: 'GET',
    url: `domainprotection/protectedDomains`,
    payload,
  };
  return await apiClient(config);
};

export const getStatusApi = async (payload: string) => {
  const config = {
    method: 'GET',
    url: `v2/assets/threats/statuses`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};
