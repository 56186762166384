import React from 'react';
import { IconButton, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ThreatViewTitle, ThreatViewWrapper } from './Style/Home.style';

const ThreatViewToggle = ({ viewPreference, handleViewToggle }: any) => {
  return (
    <>
      <ThreatViewWrapper>
        <ThreatViewTitle onClick={() => handleViewToggle('card')}>
          <IconButton>
            <CreditCardIcon
              color={viewPreference === 'card' ? 'primary' : 'inherit'}
            />
          </IconButton>
          <Typography
            variant="body1"
            color={viewPreference === 'card' ? 'primary' : 'textSecondary'}>
            Card View
          </Typography>
        </ThreatViewTitle>
        <ThreatViewTitle onClick={() => handleViewToggle('table')}>
          <IconButton>
            <TableChartIcon
              color={viewPreference === 'table' ? 'primary' : 'inherit'}
            />
          </IconButton>
          <Typography
            variant="body1"
            color={viewPreference === 'table' ? 'primary' : 'textSecondary'}>
            Table View
          </Typography>
        </ThreatViewTitle>
      </ThreatViewWrapper>
    </>
  );
};

export default ThreatViewToggle;
