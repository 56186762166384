import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { showToast } from '../store/toaster/action';

const useApiInterceptor = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (error?.response) {
        Sentry.captureException(error, {
          extra: {
            url: error?.config?.url,
            method: error?.config?.method,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            responseData: error?.response?.data,
          },
        });
      } else {
        Sentry.captureException(error, {
          extra: {
            message: error.message,
            config: error?.config,
          },
        });
      }

      if (error?.response?.status === 401) {
        dispatch(showToast('Session expired. Please log in again.', 'error'));
        await new Promise(resolve => setTimeout(resolve, 5000));
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
      return Promise.reject(error);
    },
  );
};

export default useApiInterceptor;
