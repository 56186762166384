export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;
export const REACT_APP_AUTH0_REDIRECTURL =
  process.env.REACT_APP_AUTH0_REDIRECTURL;
export const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const REACT_APP_MIXPANEL_CONFIG_ID =
  process.env.REACT_APP_MIXPANEL_CONFIG_ID;
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_BASE_URL_TAKEDOWN =
  process.env.REACT_APP_BASE_URL_TAKEDOWN;
export const REACT_APP_BASE_URL_WEBSOCKET =
  process.env.REACT_APP_BASE_URL_WEBSOCKET;
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID