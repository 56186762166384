import { styled } from '@mui/material/styles';
import { default as SimpleButton } from '@mui/material/Button';
import { GradientButtonProps } from '../../helpers/types';

const CustomButton = styled(SimpleButton)<GradientButtonProps>(
  ({ disabled }) => ({
    color: disabled ? '#A9A9A9' : 'white',
    background: disabled
      ? '#D3D3D3'
      : 'linear-gradient(45deg, #08e2ba 20%, #34a6ed 90%)',
    textTransform: 'capitalize',
    width: 'fit-content',
    height: 'fit-content',
    '&.MuiButton-root': {
      padding: '5px 13px',
    },
    '&:hover': {
      background: disabled
        ? '#D3D3D3'
        : 'linear-gradient(45deg, #08e2ba 20%, #34a6ed 90%)',
    },
  }),
);

const GradientButton = ({
  variant = 'contained',
  loading,
  label,
  disabled,
  onClick,
  size,
  type,
  children,
  sx,
  ...props
}: GradientButtonProps) => {
  return (
    <CustomButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      sx={sx}
      type={type}
      {...props}>
      {loading ? 'Loading...' : label}
      {children}
    </CustomButton>
  );
};

export default GradientButton;
