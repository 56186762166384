import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import FilterCard from './FilterCard';
import {
  changeBulkStatusAction,
  getDomainsListAction,
  getThreatAction,
  resetThreatAction,
  setPageSize,
  setReviewStatusPersistValues,
  setStatusPersistValues,
} from '../../store/threats/action';
import {
  defaultEndDate,
  defaultReviewStatusFilter,
  defaultServiceFilter,
  defaultSourcesFilter,
  defaultStartDate,
  defaultStatusFilter,
  getDateOrDefault,
  getLabelsAndIds,
  PER_PAGE_RECORDS,
  threatsStatus,
} from '../../utils';
import {
  InputContainer,
  ThreatCardWrapper,
  TotalCard,
  TotalText,
  ThreatCardContainer,
  LoaderThreatTable,
} from './Style/Home.style';
import {
  Button,
  SearchBar,
  StatusMenu,
  ConfirmationModal,
  ThreatCard,
  ThreatsSkeleton,
  GradientButton,
  Loader,
} from '../../components';
import {
  BulkThreatDataType,
  ThreatTypes,
  selectedThreatDataType,
} from '../../helpers/types';
import CreateThreatModel from './CreateThreatModel';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import AddCommonNotesModal from './AddCommonNotesModal';
import {
  getServiceAction,
  setExcludeCompany,
  setCompanyPersistValues,
  setServicesData,
  setSourcesData,
  setPickerStartDate,
  setPickerEndDate,
} from '../../store/company/action';
import { getNotesValuesAction } from '../../store/noteComments/action';
import ThreatTable from '../../components/ThreatTable/ThreatTable';
import ThreatViewToggle from './ThreatViewToggle';
import { setUserViewPreference } from '../../store/user/action';
import { REACT_APP_BASE_URL_WEBSOCKET } from '../../utils/envVar';
import {
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
} from '../../store/constant';
import { showToast } from '../../store/toaster/action';

const Home = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedThreats, setSelectedThreats] = useState<any>([]); // TODO:
  const [openStatusMenu, setOpenStatusMenu] = useState<null | HTMLElement>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [companyFilterValues, setCompanyFilterValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBulkStatusLabel, setSelectedBulkStatusLabel] =
    useState<string>('');
  const [openAddThreatModal, setOpenAddThreatModal] = useState<boolean>(false);
  const [hasMoreThreats, setHasMoreThreats] = useState<boolean>(true);
  const [networkData, setNetworkData] = useState<any>({});
  const [openCommonThreatModal, setOpenCommonThreatModal] =
    useState<boolean>(false);
  const [openSortBy, setOpenSortBy] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

  const {
    threatList: threats,
    statusPersistValues,
    reviewStatusPersistValues,
    pageSizePersistValues,
    threatsNetworkData,
  } = useSelector((state: any) => state.threats);
  const {
    companyPersistValues,
    companysExclude,
    getServicesData,
    getSourcesData,
    getPickerStartDate,
    getPickerEndDate,
  } = useSelector((state: any) => state.company);
  const currentViewPreference = useSelector(
    (state: any) => state?.user?.viewPreference,
  );

  const selectedCompanyLabels = getLabelsAndIds(companyPersistValues, 'id');
  const selectedStatusLabels = getLabelsAndIds(statusPersistValues, 'label');
  const selectedReviewStatusLabels = getLabelsAndIds(
    reviewStatusPersistValues,
    'label',
  );
  const selectedServiceLabels = getLabelsAndIds(getServicesData, 'label');
  const selectedSourceLabels = getLabelsAndIds(getSourcesData, 'value');
  const selectedCompanyExcludeLabels = getLabelsAndIds(companysExclude, 'id');
  const getThreatActionProps = {
    currentPage,
    searchValue,
    selectedCompanyLabels,
    selectedStatusLabels,
  };

  const isCustomerReviewPage =
    location.pathname === '/threats/customer-review-requests';

  useEffect(() => {
    if (isCustomerReviewPage) {
      dispatch(resetThreatAction());
      dispatch(setReviewStatusPersistValues(reviewStatusPersistValues));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(statusPersistValues));
      dispatch(resetThreatAction());
    }
    fetchThreats();
  }, [location.pathname, currentPage]);

  useEffect(() => {
    let ws;
    const RECONNECT_INTERVAL = 5000;
    const connect = async () => {
      const token: any = await getIdTokenClaims();
      const user = {
        email: token?.email,
        name: token?.name,
      };

      ws = new WebSocket(
        `${REACT_APP_BASE_URL_WEBSOCKET}?bearer=${token?.__raw}`,
      );

      ws.onmessage = function (event) {
        const wsResponse = JSON.parse(event.data);
        if (wsResponse?.channel === 'threat_status_update') {
          if (wsResponse?.data?.error) {
            dispatch(
              showToast(
                wsResponse?.data?.error || 'Something want wrong',
                'error',
              ),
            );
            dispatch({
              type: CHANGE_BULK_STATUS_FAILURE,
              payload: wsResponse?.data,
            });
          } else {
            dispatch(
              showToast(
                `${wsResponse?.data?.threat_name} status has been changed to ${wsResponse?.data?.status}`,
                'success',
              ),
            );
            dispatch({
              type: CHANGE_STATUSLABEL_SUCCESS,
              payload: wsResponse?.data,
            });
          }
        }
        if (wsResponse?.channel === 'asset_network_data') {
          setNetworkData((prevState: any) => ({
            ...prevState,
            [wsResponse?.data?.asset_id]: {
              ...prevState[wsResponse?.data?.asset_id],
              loader: false,
              ssl: wsResponse?.data?.data?.ssl_certificate ?? null,
              whois: wsResponse?.data?.data?.domain_information ?? null,
              ...(wsResponse?.data?.error && { ssl: null, whois: null }),
            },
          }));
        }
      };

      ws.onclose = function () {
        setTimeout(connect, RECONNECT_INTERVAL);
      };
    };
    if (isAuthenticated) {
      connect();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (!threats?.loading && threats.items.length > 0) {
      console.log("====threats.items.length", threats.items)
      const networkData = threats.items.reduce(
        (
          acc: { [x: string]: { ssl: null; whois: null; loader: boolean } },
          item: { threat_id: string | number },
        ) => {
          acc[item.threat_id] = { ssl: null, whois: null, loader: true };
          return acc;
        },
        {},
      );
      setNetworkData(networkData);
    }
  }, [threats]);

  const onClickRefreshNetworkData = (threat_id: number) => {
    setNetworkData((prevState: any) => ({
      ...prevState,
      [threat_id]: {
        ...prevState[threat_id],
        loader: true,
        ssl: null,
        whois: null,
      },
    }));
  };

  const fetchThreats = (
    sortOrder = 'DESCENDING',
    sortBy = 'updated_at',
    size = pageSizePersistValues || PER_PAGE_RECORDS,
  ) => {
    const params = {
      from: getDateOrDefault(getPickerStartDate, defaultStartDate),
      until: getDateOrDefault(getPickerEndDate, defaultEndDate),
      status: isCustomerReviewPage
        ? selectedReviewStatusLabels
        : selectedStatusLabels,
      threat_name: searchValue,
      company_ids: selectedCompanyLabels,
      service: selectedServiceLabels,
      sort: sortBy,
      order: sortOrder,
      page: currentPage,
      size,
      sources: selectedSourceLabels,
      excludeCompanysId: selectedCompanyExcludeLabels,
    };
    dispatch(getThreatAction(params));
  };

  useEffect(() => {
    dispatch(getNotesValuesAction());
    dispatch(getServiceAction());
  }, []);

  const handleSortBy = (order = 'detected_at', sortBy = 'updated_at') => {
    setOpenSortBy(null);
    fetchThreats(order, sortBy);
  };

  const handleUpdatedASCE = () => handleSortBy('ASCENDING', 'updated_at');
  const handleUpdatedDESC = () => handleSortBy('DESCENDING', 'updated_at');
  const handleDetectedASCE = () => handleSortBy('ASCENDING', 'detected_at');
  const handleDetectedDESC = () => handleSortBy('DESCENDING', 'detected_at');

  const handleApplyFilter = () => {
    dispatch(resetThreatAction());
    setCurrentPage(1);
    setHasMoreThreats(true);
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: isCustomerReviewPage
          ? selectedReviewStatusLabels
          : selectedStatusLabels,
        threat_name: searchValue,
        company_ids: selectedCompanyLabels,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: selectedSourceLabels,
        excludeCompanysId: selectedCompanyExcludeLabels,
      }),
    );
  };
  const handleResetFilter = () => {
    const defaultStatus = isCustomerReviewPage
      ? defaultReviewStatusFilter
      : defaultStatusFilter;

    if (isCustomerReviewPage) {
      dispatch(setReviewStatusPersistValues(defaultReviewStatusFilter));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(defaultStatusFilter));
    }

    dispatch(setPickerStartDate(defaultStartDate));
    dispatch(setPickerEndDate(defaultEndDate));
    dispatch(setCompanyPersistValues([]));
    dispatch(setExcludeCompany([]));
    setSearchValue('');
    setCompanyFilterValues([]);
    dispatch(setServicesData(defaultServiceFilter));
    dispatch(setSourcesData(defaultSourcesFilter));
    setCurrentPage(1);
    dispatch(resetThreatAction());
    dispatch(
      getThreatAction({
        from: getDateOrDefault(defaultStartDate, getPickerStartDate),
        until: getDateOrDefault(defaultEndDate, getPickerEndDate),
        status: defaultStatus?.map(status => status?.label),
        company_ids: 0,
        threat_name: '',
        service: defaultServiceFilter?.map(service => service?.label),
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: defaultSourcesFilter?.map(sources => sources.value),
      }),
    );
  };

  const handleSelectAll = useCallback(() => {
    if (selectedThreats?.length === threats?.items?.length) {
      setSelectedThreats([]);
    } else {
      const selectedThreats: {
        domain: string;
        threat: string;
        threatId: number;
        companyId: number;
        domainId: number;
        currentStatus: string;
      }[] = [];
      threats?.items?.map((threat: ThreatTypes) => {
        selectedThreats.push({
          domain: threat.protected_asset_name,
          threat: threat.threat_name,
          threatId: threat.threat_id,
          companyId: threat.company_id,
          domainId: threat.protected_asset_id,
          currentStatus: threat.identification_status.value,
        });
      });
      setSelectedThreats(selectedThreats);
    }
  }, [selectedThreats]);

  const onChangeSatatuses = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    if (isCustomerReviewPage) {
      dispatch(setReviewStatusPersistValues(value));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(value));
    }
  };

  const onChangeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setCompanyPersistValues(value));
  };

  const onChangeExcludeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setExcludeCompany(value));
  };

  const onChangeService = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    dispatch(setServicesData(value));
  };

  const onChangeSource = (
    e: React.SyntheticEvent,
    value: { label: string; value: string }[],
  ) => {
    dispatch(setSourcesData(value));
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const [start, end] = value;
      const formattedStartDate = start || '';
      const formattedEndDate = end || '';

      dispatch(setPickerStartDate(formattedStartDate));
      dispatch(setPickerEndDate(formattedEndDate));
    } else {
      dispatch(setPickerStartDate(''));
      dispatch(setPickerEndDate(''));
    }
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setOpenStatusMenu(null);
  };

  const handleConfirmModal = (value: string) => {
    setSelectedBulkStatusLabel(value);
    setOpenStatusMenu(null);
    setOpenConfirmModal(true);
  };

  const updateBulkThreatsStatus = () => {
    setOpenConfirmModal(false);
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: true,
    };
    dispatch(setAnalyticObject(analytic));
    const items = selectedThreats.map((item: BulkThreatDataType) => ({
      threat_name: item.threat,
      threatId: item.threatId,
      status: selectedBulkStatusLabel,
      user_id: user?.sub,
      company_id: item.companyId,
      process: 'w',
      priority: 1,
      protected_asset_id: item.domainId,
      domain: item.domain,
      currentStatus: item.status,
    }));
    const newData = { items };
    dispatch(changeBulkStatusAction(newData));
    setSelectedThreats([]);
  };

  const handleChangeSelectedThreats = (threatsItem: selectedThreatDataType) => {
    const indexOf = selectedThreats.findIndex(
      (threat: selectedThreatDataType) =>
        threat.threatId === threatsItem.threatId &&
        threat.domainId === threatsItem.domainId &&
        threat.companyId === threatsItem.companyId,
    );
    if (indexOf > -1) {
      const newSelectedThreats = [...selectedThreats];
      newSelectedThreats.splice(indexOf, 1);
      setSelectedThreats([...newSelectedThreats]);
    } else {
      setSelectedThreats([...selectedThreats, threatsItem]);
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: selectedStatusLabels,
        threat_name: searchValue,
        company_ids: 0,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues,
        sources: selectedSourceLabels,
      }),
    );
  };

  const handleAddThreatModal = () => {
    setOpenAddThreatModal(true);
    dispatch(getDomainsListAction(''));
  };

  const count = Math.ceil(threats?.total / pageSizePersistValues);
  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<string>) => {
    const selectedPageSize = Number(event?.target?.value);
    dispatch(setPageSize(selectedPageSize));
    setCurrentPage(1);
    fetchThreats('DESCENDING', 'updated_at', selectedPageSize);
  };

  const handleViewToggle = (newPreference: string) => {
    dispatch(setUserViewPreference(newPreference));
  };

  const isThreatSelected = useCallback(
    (threatData: selectedThreatDataType) => {
      const { companyId, domainId, threatId } = threatData;
      const indexOf = selectedThreats.findIndex(
        (threat: selectedThreatDataType) =>
          threat.threatId === threatId &&
          threat.domainId === domainId &&
          threat.companyId === companyId,
      );
      return indexOf > -1;
    },
    [selectedThreats],
  );

  return (
    <Box sx={{ padding: '24px' }}>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <TotalCard>
            <Box>
              <Typography>Total</Typography>
              <Typography sx={TotalText}>{threats?.total}</Typography>
            </Box>
            <LanguageIcon />
          </TotalCard>
        </Grid>
        <Grid item md={8}>
          <FilterCard
            onChangeSatatuses={onChangeSatatuses}
            onChangeCompany={onChangeCompany}
            onChangeService={onChangeService}
            onChangeDate={onChangeDate}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            statuesFilterValues={selectedStatusLabels}
            companyFilterValues={companyFilterValues}
            getServicesData={getServicesData}
            getPickerStartDate={getPickerStartDate}
            getPickerEndDate={getPickerEndDate}
            openSortBy={openSortBy}
            setOpenSortBy={setOpenSortBy}
            handleUpdatedASCE={handleUpdatedASCE}
            handleUpdatedDESC={handleUpdatedDESC}
            handleDetectedASCE={handleDetectedASCE}
            handleDetectedDESC={handleDetectedDESC}
            getSourcesData={getSourcesData}
            onChangeSource={onChangeSource}
            onChangeExcludeCompany={onChangeExcludeCompany}
            isCustomerReviewPage={isCustomerReviewPage}
          />
        </Grid>
      </Grid>
      <Grid item md={12} sx={InputContainer}>
        <Box sx={{ display: 'flex' }}>
          <SearchBar
            size="small"
            handleChange={handleChangeSearch}
            handleSearch={handleSearchSubmit}
            searchValue={searchValue}
          />
          <GradientButton onClick={handleAddThreatModal} sx={{ ml: 2 }}>
            + Add Threat
          </GradientButton>
        </Box>
        {selectedThreats?.length > 0 && (
          <Box sx={{ gap: 1, display: 'flex' }}>
            <InputLabel sx={{ mt: '10px', color: 'black' }}>
              {selectedThreats?.length} Selected
            </InputLabel>
            <GradientButton
              variant="contained"
              size="medium"
              label={
                selectedThreats?.length === threats?.items?.length
                  ? 'Deselected All'
                  : 'Select All'
              }
              onClick={handleSelectAll}
            />
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              label="Change Status"
              onClick={handleOpenStatusMenu}
            />
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              label="Add Notes"
              onClick={() => setOpenCommonThreatModal(true)}
            />
          </Box>
        )}
      </Grid>
      <Box sx={{ width: '100%', mt: 2 }}>
        <ThreatViewToggle
          viewPreference={currentViewPreference}
          handleViewToggle={handleViewToggle}
        />
        {currentViewPreference === 'table' ? (
          <ThreatCardContainer>
            {threats?.loading ? (
              <LoaderThreatTable>
                <Loader size={25} />
              </LoaderThreatTable>
            ) : threats?.items?.length === 0 ? (
              <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
            ) : (
              <ThreatTable
                threats={threats}
                handleChangeSelectedThreats={handleChangeSelectedThreats}
                isThreatSelected={isThreatSelected}
                getThreatActionProps={getThreatActionProps}
                threatNetworkData={networkData}
              />
            )}
            <PaginationStyle style={{ marginTop: '16px' }}>
              <Select
                value={String(pageSizePersistValues)}
                onChange={handlePageSizeChange}
                label="Size"
                fullWidth
                style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
                <MenuItem value="10">10</MenuItem>{' '}
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <Pagination
                count={count || 0}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </PaginationStyle>
          </ThreatCardContainer>
        ) : (
          <ThreatCardContainer>
            {threats.loading ? (
              <ThreatsSkeleton />
            ) : !threats || !threats.items || threats.items.length === 0 ? (
              <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
            ) : (
              <Grid container spacing={{ xs: 2 }} columns={{ md: 12 }}>
                {threats?.items?.map((items: ThreatTypes, index: number) => (
                  <Grid item md={3} key={index}>
                    <ThreatCard
                      items={items}
                      handleChangeSelectedThreats={handleChangeSelectedThreats}
                      isThreatSelected={isThreatSelected}
                      getThreatActionProps={getThreatActionProps}
                      threatNetworkData={networkData}
                      onClickRefreshNetworkData={onClickRefreshNetworkData}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <PaginationStyle style={{ marginTop: '16px' }}>
              <Select
                value={String(pageSizePersistValues)}
                onChange={handlePageSizeChange}
                label="Size"
                fullWidth
                style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
                <MenuItem value="10">10</MenuItem>{' '}
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <Pagination
                count={count || 0}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </PaginationStyle>
          </ThreatCardContainer>
        )}
      </Box>
      <CreateThreatModel
        open={openAddThreatModal}
        handleClose={() => setOpenAddThreatModal(false)}
      />
      <AddCommonNotesModal
        open={openCommonThreatModal}
        handleClose={() => setOpenCommonThreatModal(false)}
        selectedThreats={selectedThreats}
      />
      <StatusMenu
        items={threatsStatus}
        openStatusMenu={openStatusMenu}
        closeStatusMenu={handleCloseStatusMenu}
        handleOnClick={handleConfirmModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={() => updateBulkThreatsStatus()}
      />
    </Box>
  );
};

export default Home;
