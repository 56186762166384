import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Input } from '../../../components';
import {
  CustomTableCell,
  StyledLink,
} from '../../CompaniesList/Style/CompaniesList.style';
import {
  getCompanyDetailAction,
  getCompanyListAction,
  unsetParentCompanyAction,
} from '../../../store/company/action';
import { LoaderWrapper, NoDataMessage, formatedDate } from '../../../utils';
import { ActionsButton } from './Style/Tabs.style';
import { PaginationStyle } from '../../ThreadsDetails/Style/SingleThreat.style';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  p: 2.5,
};

const ChildCompaniesTab = () => {
  const [searchChildCompany, setSearchChildCompany] = useState<string>('');
  const [openActionMenu, setOpenActionMenu] = useState<null>(null);
  const [selectedChild, setSelectedChild] = useState<any>(null);
  const [childCurrentPage, setChildCurrentPage] = useState<number>(1);

  const open = Boolean(openActionMenu);
  const { id }: any = useParams();
  const companyId = parseInt(id, 10);

  const dispatch = useDispatch();
  const { getCompanyList } = useSelector((state: any) => state.company);
  const getChildCompany = getCompanyList?.data?.Companies;

  useEffect(() => {
    dispatch(
      getCompanyListAction({
        d: 'n',
        s: 'y',
        u: 'n',
        h: searchChildCompany,
        ipp: 10,
        pn: childCurrentPage,
        c: companyId,
      }),
    );
  }, [searchChildCompany, companyId, childCurrentPage]);

  const filteredChildCompanies = getChildCompany?.filter(
    (item: any) => item?.Id !== companyId,
  );
  const childCount = Math.ceil(getCompanyList?.data?.Total / 10);

  const handleSearchChildName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchChildCompany(e.target.value);
  };

  const handleChildActions = (event: any, item: any) => {
    setOpenActionMenu(event.currentTarget);
    setSelectedChild(item.Id);
  };

  const handleChildCompany = (companyId: string) => {
    if (companyId) {
      dispatch(getCompanyDetailAction({ id: companyId }));
    }
  };

  const handleUnsetParent = (companyId: string) => {
    dispatch(unsetParentCompanyAction({ companyId: companyId, parentId: id }));
    setOpenActionMenu(null);
  };

  const handleChangeChildPage = (event: any, value: number) => {
    setChildCurrentPage(value);
  };

  return (
    <div>
      <Paper elevation={1} sx={PaperStyle}>
        <Box sx={{ mb: '24px' }}>
          <Input
            size="small"
            placeholder="search for name"
            onChange={handleSearchChildName}
            value={searchChildCompany}
          />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>COMPANY NAME</CustomTableCell>
              <CustomTableCell align="left">STATUS</CustomTableCell>
              <CustomTableCell align="left">SERVICES</CustomTableCell>
              <CustomTableCell align="left">CREATED DATE</CustomTableCell>
              <CustomTableCell align="left">ACTIONS</CustomTableCell>
            </TableRow>
          </TableHead>
          {getCompanyList?.loading ? (
            <LoaderWrapper size={25} />
          ) : filteredChildCompanies?.length === 0 ? (
            <NoDataMessage message="No Child Company" />
          ) : (
            <TableBody>
              {filteredChildCompanies?.map((item: any) => (
                <React.Fragment key={item?.Id}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <StyledLink
                        to={`/companies/${encodeURIComponent(item?.Id)}/view`}
                        onClick={() => handleChildCompany(item?.Id)}>
                        {item?.Name}
                      </StyledLink>
                    </TableCell>
                    <TableCell align="left">{item?.Status || '-'}</TableCell>
                    <TableCell align="left">
                      {item.Services?.map((item: any) => item.Name).join(
                        ', ',
                      ) || '-'}
                    </TableCell>
                    <TableCell align="left">
                      {formatedDate(item?.Created) || '-'}
                    </TableCell>
                    <TableCell align="left">
                      <ActionsButton
                        size="small"
                        onClick={event => handleChildActions(event, item)}
                        variant="contained"
                        endIcon={<KeyboardArrowDownIcon />}>
                        Actions
                      </ActionsButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
        {getCompanyList?.data?.Total > 10 && !getCompanyList?.loading && (
          <PaginationStyle>
            <Pagination
              count={childCount}
              page={childCurrentPage}
              onChange={handleChangeChildPage}
              color="primary"
            />
          </PaginationStyle>
        )}
        <Menu
          anchorEl={openActionMenu}
          open={open}
          onClose={() => setOpenActionMenu(null)}>
          <StyledLink
            to={`/companies/${encodeURIComponent(selectedChild)}/view`}>
            <MenuItem onClick={() => handleChildCompany(selectedChild)}>
              View
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/companies/${encodeURIComponent(selectedChild)}/view`}>
            <MenuItem onClick={() => handleChildCompany(selectedChild)}>
              Update
            </MenuItem>
          </StyledLink>
          <StyledLink to="">
            <MenuItem onClick={() => handleUnsetParent(selectedChild)}>
              Unset Parent
            </MenuItem>
          </StyledLink>
          <MenuItem>Delete</MenuItem>
        </Menu>
      </Paper>
    </div>
  );
};

export default ChildCompaniesTab;
