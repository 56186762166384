import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, IconButton } from '@mui/material';

export const TotalCard = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  ...theme.typography.body2,
  padding: '30px 20px',
  border: '1px solid #e2e2e2',
  textAlign: 'left',
  color: 'black',
  display: 'flex',
  borderRadius: 14,
  justifyContent: 'space-between',
  boxShadow: 'none',
}));

export const ThreatsTotal = styled(Box)({
  fontWeight: 'bold',
  marginTop: '4px',
});

export const TotalText = {
  fontWeight: 'bold',
  mt: 2,
};

export const InputContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 3,
};

export const ThreatCardContainer = styled(Box)({
  flexGrow: 1,
  marginTop: '16px',
});

export const LoaderThreatTable = styled(Box)({
  alignItems: 'center',
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
});

export const ThreatCardWrapper = styled(Box)({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  marginTop: '12%',
});

export const ThreatViewWrapper = styled(Box)({
  display: 'flex',
  gap: '12px',
  marginBottom: '12px',
});

export const ThreatViewTitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #000',
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
}));

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '24px',
});

export const ChipsWrapper = styled(Box)({
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
  marginTop: '8px',
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  color: theme.palette.text.primary,
}));
